// Old sites pages does not have managingAppDefId, which is needed for platformised pages panel
import * as routersWrapper from '../wrappers/routers';
import * as pagesService from '../services/pages';
import { log } from '../../utils/monitoring';

export async function maybeSetManagingAppDefIdForMAPages({ editorSDK: editorSDK_, options }) {
  if (options.firstInstall) {
    return;
  }

  try {
    const routers = await routersWrapper.getAll(editorSDK_);
    const pagesRefs = routers.reduce((acc, router) => [...acc, ...router.pages.map((p) => p.pageRef)], []);
    await Promise.all(
      pagesRefs.map((pageRef) => pagesService.updatePageWithManagingAppDefId({ editorSDK: editorSDK_, pageRef })),
    );
  } catch (e) {
    log('Failed to set managingAppDefId for MA pages, reason:', e.toString());
  }
}
