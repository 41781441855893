import * as routersWrapper from '../wrappers/routers';
import * as applicationState from '../applicationState';
import { log } from '../../utils/monitoring';
import * as menusWrapper from '../wrappers/menus';
import * as menusService from '../services/menus';

async function logMissingRouters(editorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length !== 2) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const extra = {
      routers: JSON.stringify(routers),
      applicationState: JSON.stringify(state),
    };
    log('Missing routers when they should be apparent ', { extra });
  }
}

async function fixBrokenMenuItems(editorSDK) {
  const menuIds = menusWrapper.getMenuIds();
  const menus = await Promise.all([
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.members }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.login }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.icons }),
  ]);

  // If all menus aren't apparent, don't fix them - MA state is too broken already
  if (menus.filter((m) => !!m).length !== 3) {
    return;
  }

  await menusService.maybeCleanUpMenus(editorSDK);
}

export async function maybeFixBrokenMenuItems(editorSDK) {
  try {
    await logMissingRouters(editorSDK);
    await fixBrokenMenuItems(editorSDK);
  } catch (e) {
    log('An error was thrown while fixing a broken installation, reason: ' + e);
  }
}
