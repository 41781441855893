// Workaround for OB-19052
import * as appState from '../services/applicationState';
import * as componentsWrapper from '../wrappers/components';
import { log } from '../../utils/monitoring';
import * as controllersWrapper from '../wrappers/controllers';

export async function verifyNoMissingLoginInADI(firstInstall, editorSDK) {
  try {
    if (!appState.getIsADI()) {
      return;
    }

    const hasLoginComponent = await componentsWrapper.isLoginBarComponentAdded({ editorSDK });

    if (!hasLoginComponent) {
      log('OB-19052: Login component missing in ADI', { tags: { firstInstall } });
      try {
        const controllerRef = await controllersWrapper.getController(editorSDK);
        const header = await editorSDK.siteSegments.getHeader();
        await componentsWrapper.addLoginButton(editorSDK, controllerRef, header);
      } catch (e) {
        log('OB-19052: Re-adding login component failed', { tags: { reason: e.toString() } });
      }
    }
  } catch (e) {
    log('OB-19052: Verification of login component failed', { tags: { reason: e.toString() } });
  }
}
