// For investigation purposes of MA-84
import * as menusWrapper from '../wrappers/menus';
import * as constants from '../constants';
import { log } from '../../utils/monitoring';

export async function verifyMyAccountPage(options, editorSDK) {
  if (options.firstInstall) {
    return;
  }

  try {
    const menuItems = await menusWrapper.getMenuItems({ editorSDK, menuId: constants.MENU_IDS.SUB_MENU_ID });
    const myAccountMenuItem = menuItems.find((i) => i.link.innerRoute === 'my-account');
    const allPages = await editorSDK.pages.data.getAll();
    const myAccountPage = allPages.find((p) => p.tpaPageId === 'member_info');

    if (!myAccountPage && !!myAccountMenuItem) {
      log('editorLoad: MA-84 My account menu item is there, but the page is missing');
    }
  } catch (e) {
    log('Verifying My Account page failed', { tags: { reason: e.toString() } });
  }
}
