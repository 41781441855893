import * as applicationState from '../../applicationState';
import { log } from '../../../utils/monitoring';
import { removeBrokenInstallation } from './removeBrokenInstallation';

export async function maybeRemoveEmptyInstallation(_editorSDK, options) {
  const isEmpty = await applicationState.isEmpty(_editorSDK);
  if (!options.firstInstall && isEmpty) {
    log('Removing components for empty installation as it will not install anyway and will surely fail');
    await removeBrokenInstallation(_editorSDK, false);
    return { shouldContinueInitialization: false };
  }
  return { shouldContinueInitialization: true };
}
