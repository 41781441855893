import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from '../constants';
import { areTransactionsEnabled } from '../../utils/experiments';

export type Action<T> = () => T;

const runAndWaitForApproval = async <T>(editorSDK: EditorSDK, action: Action<T>): Promise<ReturnType<Action<T>>> => {
  const transactionsEnabled = await areTransactionsEnabled();
  if (!transactionsEnabled) {
    return action();
  }

  return editorSDK.document.transactions.runAndWaitForApproval(APP_TOKEN, action);
};

export { runAndWaitForApproval };
