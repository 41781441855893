import {
  COMMON_LOGIN_BAR_COMP_DATA,
  COMMON_LOGIN_BAR_STYLE_PROPERTIES,
  COMMON_LOGIN_COMP_CONNECTIONS,
  COMMON_LOGIN_COMP_PROPS,
} from './common-login-bar-layouts';

const LOGIN_COMP_APP_WIDGET = {
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetSkin',
    style: {},
  },
  type: 'Container',
  layout: {
    width: 120,
    height: 40,
    x: 850,
    y: 24,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  layoutResponsive: {
    type: 'LayoutData',
    componentLayouts: [
      {
        type: 'ComponentLayout',
        width: { type: 'px', value: 120 },
        height: { type: 'px', value: 40 },
        minHeight: { type: 'px', value: 40 },
      },
    ],
    itemLayouts: [
      {
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        justifySelf: 'end',
        alignSelf: 'center',
        margins: {
          left: { type: 'px', value: 0 },
          top: { type: 'px', value: 0 },
          right: { type: 'px', value: 70 },
          bottom: { type: 'px', value: 0 },
        },
      },
    ],
    containerLayouts: [
      {
        type: 'GridContainerLayout',
        rows: [{ type: 'fr', value: 1 }],
        columns: [{ type: 'fr', value: 1 }],
      },
    ],
  },
  data: {
    type: 'AppController',
    applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    name: 'login-bar-controller',
    controllerType: 'members-login-bar',
    id: 'login-bar-controller-id',
  },
};

const LOGIN_COMP_RESPONSIVE_LAYOUT = {
  type: 'LayoutData',
  componentLayouts: [
    {
      type: 'ComponentLayout',
      width: { type: 'px', value: 120 },
      height: { type: 'px', value: 40 },
      minHeight: { type: 'px', value: 40 },
    },
  ],
  itemLayouts: [
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      justifySelf: 'stretch',
      alignSelf: 'start',
      margins: {
        left: { type: 'px', value: 0 },
        top: { type: 'px', value: 0 },
        right: { type: 'px', value: 0 },
        bottom: { type: 'px', value: 0 },
      },
    },
  ],
  containerLayouts: [],
};

const LOGIN_COMP_RESPONSIVE_STYLE = {
  type: 'StylesInBreakpoint',
  skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
  stylesInBreakpoints: [
    {
      type: 'ComponentStyle',
      skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
      componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
      styleType: 'custom',
      style: {
        properties: COMMON_LOGIN_BAR_STYLE_PROPERTIES,
        propertiesSource: {},
        groups: {},
      },
    },
  ],
};

const LOGIN_COMP_LAYOUT = {
  width: 120,
  height: 40,
  scale: 1,
  rotationInDegrees: 0,
  fixedPosition: false,
  x: 0,
  y: 0,
};

const LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF = {
  ...LOGIN_COMP_APP_WIDGET,
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
      componentType: 'wysiwyg.viewer.components.LoginSocialBar',
      data: COMMON_LOGIN_BAR_COMP_DATA,
      props: COMMON_LOGIN_COMP_PROPS,
      style: LOGIN_COMP_RESPONSIVE_STYLE,
      layout: LOGIN_COMP_LAYOUT,
      layoutResponsive: LOGIN_COMP_RESPONSIVE_LAYOUT,
      connections: COMMON_LOGIN_COMP_CONNECTIONS,
    },
  ],
};
export { LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF };
